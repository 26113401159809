import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Section from '../components/section';

export default function About({ data }) {
  const pageInfo = {
    id: 'about-page',
    url: '/about',
    title: 'О клинике',
    description: 'О КМК клинике.',
    breadcrumbs: [
      {
        title: 'Главная',
        link: '/',
      },
      {
        title: 'О клинике',
        link: null,
      },
    ],
  };

  return (
    <Layout pageInfo={pageInfo}>
      <Section>
        <article>
          <StaticImage
            src="../assets/img/team.png"
            alt="Команда КМК"
          />
          <ReactMarkdown>
            {data.strapiGeneral.about}
          </ReactMarkdown>
          <div className="licence">
            <GatsbyImage
              image={data.strapiGeneral.licence.childImageSharp.gatsbyImageData}
              alt="Лицензия"
            />
          </div>
        </article>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query {
    strapiGeneral {
      name
      about
      licence {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;
